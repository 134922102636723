@import url(https://fonts.googleapis.com/css2?family=Poppins:ital@1&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@100;500&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  text-align: center;
}

main {
  width: 100%;
}

.black-landing {
  background-color: black !important;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.23);
}

.menu-item {
  margin-left: 0.2em;
}

.container {
  float: none;
  margin: 0 auto;
  width: 15%;
}

.app {
  text-align: center;
}

.landing {
  color: white;
  background-color: black;
}

.landing-container {
  max-width: 1600px;
}

.landing-nav {
  padding-left: 2rem;
  text-decoration: none !important;
}

.landing-nav-p:hover {
  color: #11f211 !important;
}

.landing-nav-p {
  cursor: pointer;
  display: inline-block;
}

.green-hover:hover {
  color: #11f211 !important;
}

.form {
  justify-content: center;
  padding-top: 2%;
}

.team-logo {
  cursor: pointer;
}

.transferPlayerEntry:hover {
  cursor: pointer;
}

.grey-out {
  cursor: pointer;
  opacity: 0.3;
  filter: alpha(opacity=40); /* msie */
}

/* NEW CSS */
.smoke-hoverb:hover {
  background-color: #535353;
  border-radius: 8px;
}

.black-smoke:hover {
  background-color: #121212;
  border-radius: 8px;
}

.smoke-hoverw:hover {
  background-color: whitesmoke;
}

.footer-smoke:hover {
  color: white !important;
}

.footer-smoke-img:hover {
  -webkit-filter: brightness(0) invert(1) !important;
          filter: brightness(0) invert(1) !important;
}

.smoke {
  background-color: rgba(0, 255, 34, 0.15) !important;
}

.result-entry-mainpage {
  display: "block";
  padding-top: 1.2em;
  padding-bottom: 1.2em;
}

.frontpage-paper-header {
  font-weight: bold;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.icon-colorw {
  color: #212121;
}

.icon-colorb {
  color: #ffffff;
}

.Countdown-col {
  display: inline-block;
}

.Countdown-col-element {
  display: inline-block;
  margin: 0 20px;
  display: flex;
  flex-direction: column;
}

.Countdown-col-element strong {
  font-size: 20px;
}

.main-theme-color {
  background-color: #212121 !important;
}

.red {
  background-color: #d10b26 !important;
}

.green {
  background-color: #43a047 !important;
}

@media only screen and (min-width: 960px) {
  .front-title {
    padding-right: 72px;
  }

  .form {
    margin-left: 10%;
    margin-right: 10%;
  }
  .date-result {
    float: left;
    padding-left: 2rem;
  }
}

@media (max-width: 560px) {
  .responsive-chart {
    position: relative;
    height: 40vh;
    width: 80vw;
  }
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

